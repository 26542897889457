import { Container } from '@mui/material'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKButton from 'components/MaterialKit/MKButton'
import { BadgeIcon, ImageIcon, InfoIcon, SaveIcon, ShareIcon } from 'components/UI/CKIcons'
import { CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useArtistEdit } from 'services/Artist/UseArtistEdit'
import { UserPageContainer } from '../UserPageContainer'
import AboutForm from './AboutForm'
import GeneralForm from './GeneralForm'
import UserImageForm from './UserImageForm'

const PageUserEdit: FC = () => {
    const { userId } = useParams<{ userId: string }>()
    const { user, loading, saving, save, errors } = useArtistEdit(userId)

    const sidebarTabs = {
        root: {
            General: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: <GeneralForm user={user} errors={errors} />,
            },
            Image: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: user && <UserImageForm user={user} />,
            },
            Socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={user} />,
            },
            About: {
                title: 'Over jou',
                icon: <BadgeIcon />,
                component: <AboutForm user={user} />,
            },
        },
    }

    return (
        <UserPageContainer user={user} loading={loading}>
            <CKPageTitle>{'Pas profiel aan'}</CKPageTitle>
            <Container className="my-3">
                {user && (
                    <CKSidebarTabs
                        sections={sidebarTabs}
                        footer={
                            <div className="text-center">
                                <MKButton
                                    variant="gradient"
                                    color="info"
                                    onClick={save}
                                    disabled={saving}
                                >
                                    {saving ? (
                                        <ClipLoader size={15} color={'white'} />
                                    ) : (
                                        <SaveIcon className="me-2" />
                                    )}
                                    Sla op!
                                </MKButton>
                            </div>
                        }
                        hideGroup
                    />
                )}
            </Container>
        </UserPageContainer>
    )
}

export default PageUserEdit

import { Grid } from "@mui/material"
import MKBox from "components/MaterialKit/MKBox"
import MKButton from "components/MaterialKit/MKButton"
import MKTypography from "components/MaterialKit/MKTypography"
import { CalendarMonthIcon, PDFIcon } from "components/UI/CKIcons"
import Event from "models/Event"
import { FC, useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import AuthService from "services/AuthService"
import TimeUtils from "utils/TimeUtils"
import { useHistory } from 'react-router-dom'

interface IEventTicketsCard {
    event: Event
}

export const EventTicketsCard: FC<IEventTicketsCard> = ({ event }) => {

    const EventInfo = ({ event }: { event: Event }) => (
        <Grid container columnSpacing={3}>
            <Grid item xs={5} md={3} style={{ textAlign: 'left' }}>
                <MKTypography variant="button" color="text" style={{ fontWeight: 'bold' }}>
                    Datum:
                </MKTypography>
            </Grid>
            <Grid item xs={7} md={9} style={{ textAlign: 'left' }}>
                <MKTypography variant="button">
                    {TimeUtils.toDateString(event.$start_time, true)}
                </MKTypography>
            </Grid>
            <Grid item xs={5} md={3} style={{ textAlign: 'left' }}>
                <MKTypography variant="button" color="text" style={{ fontWeight: 'bold' }}>
                    Start:
                </MKTypography>
            </Grid>
            <Grid item xs={7} md={9} style={{ textAlign: 'left' }}>
                <MKTypography variant="button">
                    {TimeUtils.toTimeString(event.$start_time)}
                </MKTypography>
            </Grid>
            <Grid item xs={5} md={3} style={{ textAlign: 'left' }}>
                <MKTypography variant="button" color="text" style={{ fontWeight: 'bold' }}>
                    Locatie:
                </MKTypography>
            </Grid>
            <Grid item xs={7} md={9} style={{ textAlign: 'left' }} className="text-truncate">
                <MKTypography component="div" variant="button" className="pt-1">
                    <div>
                        {event.$location.$address.$street} {event.$location.$address.$streetNumber}
                    </div>
                    <div>
                        {event.$location.$address.$postalCode} {event.$location.$address.$city}
                    </div>
                </MKTypography>
            </Grid>
        </Grid>
    )

    const Buttons = ({ event }) => {
        const [isDownloading, setIsDownloading] = useState(false)
        const history = useHistory()

        const downloadTickets = () => {
            setIsDownloading(true)
            const eventSlug = event.$slug
            const url = `${process.env.REACT_APP_API_URL}events/${eventSlug}/tickets/pdf`

            const anchor = document.createElement('a')
            document.body.appendChild(anchor)

            const headers = new Headers()
            headers.append('Authorization', `Bearer ${AuthService._getJWT()}`)

            fetch(url, { headers })
                .then((response) => response.blob())
                .then((blobby) => {
                    const objectUrl = window.URL.createObjectURL(blobby)

                    anchor.href = objectUrl
                    anchor.download = `tickets-${event.$slug}.pdf`
                    anchor.click()

                    window.URL.revokeObjectURL(objectUrl)
                    setIsDownloading(false)
                })
        }
        return (
            <Grid container className="mt-4" spacing={1}>
                <Grid item xs={12} md={6} className="d-flex justify-content-center">
                    <MKButton
                        color="primary"
                        variant="outlined"
                        fullWidth
                        style={{ maxWidth: '200px' }}
                        className="py-2 px-3"
                        onClick={() => downloadTickets()}
                    >
                        {isDownloading ? (
                            <ClipLoader size={15} color="primary" className="me-2" />
                        ) : (
                            <PDFIcon className="me-2" />
                        )}
                        Download tickets
                    </MKButton>
                </Grid>
                <Grid item xs={12} md={6} className="d-flex justify-content-center">
                    <MKButton
                        color="info"
                        variant="outlined"
                        fullWidth
                        style={{ maxWidth: '200px' }}
                        className="py-2 px-3"
                        onClick={() => {
                            history.push(`/event/${event.$slug}`)
                        }}
                    >
                        <CalendarMonthIcon className="me-2" /> Open evenement
                    </MKButton>
                </Grid>
            </Grid>
        )
    }

    return (
        <Card className="mb-4">
            <CardHeader style={{ background: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))", color: 'white', height: '75px' }}>
                <CardTitle className="font-weight-bold d-flex align-items-center text-truncate" >
                    <div >

                        <MKBox
                            className="me-3"
                            component="img"
                            my="2"
                            src={event.$logoUrl}
                            alt={event.$name}
                            borderRadius="md"
                            height="50px"
                            width="50px"
                        // shadow="lg"
                        />
                    </div>
                    <p className="mb-0 text-truncate">
                        <strong>{event.$name}</strong>
                    </p>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <EventInfo event={event} />
                <Buttons event={event} />
            </CardBody>
        </Card >
    )
}
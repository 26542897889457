import { Chip, ListItemButton, ListItemIcon, Tooltip } from '@mui/material'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { DeleteIcon, ErrorIcon } from 'components/UI/CKIcons'
import CustomLineupSpot from 'models/CustomLineupSpot'
import { FC } from 'react'

interface CustomLineupSpotItemProps {
    spot: CustomLineupSpot
    onRemove: (spot: CustomLineupSpot) => void
    onSelect: (spot: CustomLineupSpot) => void
    error?: any
}

export const CustomLineupSpotItem: FC<CustomLineupSpotItemProps> = ({
    spot,
    onRemove,
    onSelect,
    error,
}) => {
    const hasErrors = error && Object.values(error).some((err) => err !== false)

    return (
        <ListItemButton onClick={() => onSelect(spot)} divider>
            <ListItemIcon className="d-flex align-items-center">
                <MKAvatar src={spot.$pictureUrl} size="sm" shadow="sm" sx={{ bgColor: '#fffffcc' }} />
                {spot.$isMc && <Chip label="MC" color="info" variant="outlined" className="mx-2" />}
                {hasErrors && (
                    <Tooltip
                        title={
                            <div>
                                {error.first_name && <div>Voornaam: {error.first_name}</div>}
                                {error.last_name && <div>Achternaam: {error.last_name}</div>}
                            </div>
                        }
                        className="mx-1"
                    >
                        <ErrorIcon color="error" />
                    </Tooltip>
                )}
            </ListItemIcon>
            <MKTypography variant="body" fontWeight="bold">
                {spot.$name}
            </MKTypography>
            <MKButton
                color="error"
                onClick={(e) => {
                    e.stopPropagation()
                    onRemove(spot)
                }}
                sx={{ marginLeft: 'auto' }}
                title="Verwijder"
            >
                <DeleteIcon />
            </MKButton>
        </ListItemButton>
    )
}

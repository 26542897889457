import { Grid } from '@mui/material'
import CKEditImageField from 'components/Form/CKEditImageField'
import Organisation from 'models/Organisation'
import { FC } from 'react'

interface Props {
    organisation: Organisation
}

const OrganisationImageForm: FC<Props> = ({ organisation }) => {
    return (
        <Grid container spacing={3}>
            <CKEditImageField
                xs={12}
                md={4}
                label="Logo"
                fieldName="logo"
                getValue={() => organisation.$logo}
                setValue={(value) => {
                    organisation.$logo = value
                }}
                model={organisation}
                urlValue={() => organisation.$logoUrl}
            />
            <CKEditImageField
                xs={12}
                md={8}
                label="Banner"
                fieldName="banner"
                getValue={() => organisation.$banner}
                setValue={(value) => {
                    organisation.$banner = value
                }}
                model={organisation}
                urlValue={() => organisation.$bannerUrl}
            />
        </Grid>
    )
}

export default OrganisationImageForm

export const DEFAULT_PROFILE_PICTURE = "https://comedy-kit.s3.eu-central-1.amazonaws.com/profile_default.png"
export const DEFAULT_PROFILE_BANNER = "https://comedy-kit.s3.eu-central-1.amazonaws.com/profile_banner_default.png"

export const DEFAULT_LOCATION_LOGO = "https://comedy-kit.s3.eu-central-1.amazonaws.com/location_default.png"
export const DEFAULT_LOCATION_BANNER = "https://comedy-kit.s3.eu-central-1.amazonaws.com/location_banner_default.png"

export const DEFAULT_EVENT_LOGO = "https://comedy-kit.s3.eu-central-1.amazonaws.com/event_default.png"
export const DEFAULT_EVENT_BANNER = "https://comedy-kit.s3.eu-central-1.amazonaws.com/event_banner_default.png"

export const DEFAULT_ORGANISATION_LOGO = "https://comedy-kit.s3.eu-central-1.amazonaws.com/organisation_default.png"
export const DEFAULT_ORGANISATION_BANNER = "https://comedy-kit.s3.eu-central-1.amazonaws.com/organisation_banner_default.png"

export const DEFAULT_WORKSHOP_LOGO = "https://comedy-kit.s3.eu-central-1.amazonaws.com/workshop_default.png"
export const DEFAULT_WORKSHOP_BANNER = "https://comedy-kit.s3.eu-central-1.amazonaws.com/workshop_banner_default.png"
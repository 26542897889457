import { Grid } from '@mui/material'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import { CKOrganisationSelect } from 'components/Form/CKOrganisationSelect'
import MKInput from 'components/MaterialKit/MKInput'
import Event from 'models/Event'
import { FC } from 'react'
import { EventTimes } from './EventTimes'

interface Props {
    event: Event
    errors: any
}

const GeneralForm: FC<Props> = ({ event, errors }) => {
    if (!event) return null

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MKInput
                    label="Naam"
                    error={errors['name'] ? true : false}
                    title="Naam"
                    defaultValue={event.$name}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                        event.$name = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['name']}</CKFormFeedback>
            </Grid>

            <Grid item xs={12}>
                <MKInput
                    multiline
                    defaultValue={event.$description ?? ''}
                    placeholder="Geef een beschrijving van het evenement..."
                    title="Beschrijving"
                    label="Beschrijving"
                    variant="outlined"
                    onChange={(e) => {
                        event.$description = e.target.value
                    }}
                    InputLabelProps={{ shrink: true }}
                    rows={8}
                    fullWidth
                />
            </Grid>

            <EventTimes
                start={event.$start_time}
                arrival={event.$arrival_time}
                errors={errors}
                onChange={(start, arrival) => {
                    event.$start_time = start
                    event.$arrival_time = arrival
                }}
            />

            <Grid item xs={12} md={12}>
                <CKOrganisationSelect
                    defaultOrganisation={event.$organisation}
                    onChange={(org) => {
                        event.$organiser = org
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default GeneralForm

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import User from 'models/User'
import UserService from '../UserService'
import PendingImage from 'models/PendingImage'
import ImageService from 'services/ImageService'
import ComedyTagService from 'services/ComedyTagService'
import ComedyTag from 'models/ComedyTag'
import { useUserContext, useUserContextDispatch } from 'contexts/UserContext'
import { useArtist } from '../UserService'

export function useArtistEdit(userId: string) {
    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<Record<string, any>>({})
    const [saving, setSaving] = useState(false)
    const [selectedComedyTags, setSelectedComedyTags] = useState<ComedyTag[]>([])
    const [unselectedComedyTags, setUnselectedComedyTags] = useState<ComedyTag[]>([])
    
    const history = useHistory()
    const { user: loggedInUser } = useUserContext()
    const userDispatch = useUserContextDispatch()
    const { mutate } = useArtist(userId)

    useEffect(() => {
        const loadUser = async () => {
            if (!userId) return
            setLoading(true)
            try {
                if (userId === 'new') {
                    setUser(new User())
                } else {
                    const loadedUser = await UserService.get(userId)
                    if (!loadedUser.hasRight('FULL')) history.push(loadedUser.$detailUrl)
                    setUser(loadedUser)
                    
                    // Load and setup comedy tags
                    const selectedTagsIds = loadedUser.$comedy_tags.map(tag => tag.$id)
                    const allTags = await ComedyTagService.getAll()
                    
                    setSelectedComedyTags(loadedUser.$comedy_tags)
                    setUnselectedComedyTags(
                        allTags.filter(tag => !selectedTagsIds.includes(tag.$id))
                    )
                }
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadUser()
    }, [userId, history])

    const validate = () => {
        if (!user) return false
        const _errors = {}
        const defaultMsg = 'Verplicht veld!'

        _errors['first_name'] = !user.$first_name ? defaultMsg : false
        _errors['last_name'] = !user.$last_name ? defaultMsg : false
        _errors['email'] = !user.$email ? defaultMsg : false
        _errors['birthdate'] = !user.$birthdate ? defaultMsg : false

        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        if (!user) return
        setSaving(true)
        try {
            if (!validate()) return

            // Upload picture if it's a pending image
            if (user.$picture instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: user.$picture.$file,
                    name: `${user.$name} - PICTURE`,
                    type: 'LOGO',
                })
                user.$picture = image
            }

            // Upload banner if it's a pending image
            if (user.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: user.$banner.$file,
                    name: `${user.$name} - BANNER`,
                    type: 'BANNER',
                })
                user.$banner = image
            }

            // Update comedy tags
            user.$comedy_tags = selectedComedyTags

            const updatedUser = await UserService.update(user)
            
            // Update global user state if editing logged in user
            if (updatedUser.$id === loggedInUser?.$id) {
                userDispatch({
                    type: 'REFRESH',
                    user: updatedUser,
                })
            }
            mutate()
            history.push(updatedUser.$detailUrl)
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    return {
        user,
        loading,
        saving,
        errors,
        save,
        selectedComedyTags,
        setSelectedComedyTags,
        unselectedComedyTags,
        setUnselectedComedyTags
    }
} 
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import { Container } from '@mui/material'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ImageIcon, InfoIcon, SaveIcon, ShareIcon } from 'components/UI/CKIcons'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useLocationEdit } from 'services/Location/UseLocationEdit'
import { LocationPageContainer } from '../LocationPageContainer'
import GeneralForm from './GeneralForm'
import LocationImageForm from './LocationImageForm'

interface Props {}

const PageLocationEdit: FC<Props> = () => {
    const { locationId } = useParams<{ locationId: string }>()
    const { location, loading, saving, save, errors } = useLocationEdit(locationId)

    const sidebarTabs = {
        root: {
            general: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: <GeneralForm location={location} errors={errors} />,
            },
            afbeeldingen: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: location && <LocationImageForm location={location} />,
            },
            socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={location} />,
            },
        },
    }

    return (
        <LocationPageContainer location={location} loading={loading}>
            <CKPageContent>
                {location?.$logoUrl ? (
                    <CKPageBadge src={location.$logoUrl} />
                ) : (
                    <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                        <DomainAddIcon fontSize="large" style={{ color: 'white' }} />
                    </CKPageBadge>
                )}
                <CKPageTitle>
                    {locationId === 'new' ? 'Creëer een locatie' : 'Pas locatie aan'}
                </CKPageTitle>
                <MKTypography variant="caption" className="text-center">
                    Hoppa, nog een plaats erbij om te lachen. Vul de gegevens van jouw locatie hier in
                    en zorg ervoor dat je meute aan comedians je optijd kunnen vinden!
                </MKTypography>
                <Container className="my-3">
                    <CKSidebarTabs
                        sections={sidebarTabs}
                        footer={
                            <div className="text-center">
                                <MKButton
                                    variant="gradient"
                                    color="info"
                                    onClick={save}
                                    disabled={saving}
                                >
                                    {saving ? (
                                        <ClipLoader size={15} color={'white'} />
                                    ) : (
                                        <SaveIcon className="me-2" />
                                    )}
                                    {locationId === 'new' ? 'Creëer locatie!' : 'Sla op!'}
                                </MKButton>
                            </div>
                        }
                        hideGroup
                    />
                </Container>
            </CKPageContent>
        </LocationPageContainer>
    )
}

export default PageLocationEdit

import Event from 'models/Event'
import Organisation from 'models/Organisation'
import CKIndexProps from 'utils/CKIndexProps'
import AuthService from '../AuthService'
import { ORGANISATIONS } from '../Constants'

const OrganisationService = {
    get: async function (slug: string) {
        const resp = await AuthService.get(`${ORGANISATIONS}/${slug}`)

        const loc = Organisation.fromJSON(resp.data)
        return loc
    },
    update: async function (organisation: Organisation) {
        const resp = await AuthService.put(`${ORGANISATIONS}/${organisation.$slug}`, organisation)

        const loc = Organisation.fromJSON(resp.data)
        return loc
    },
    post: async function (organisation: Organisation) {
        const resp = await AuthService.post(`${ORGANISATIONS}`, organisation)

        const loc = Organisation.fromJSON(resp.data)
        return loc
    },
    index: async function (data: CKIndexProps) {
        const resp = await AuthService.get(`${ORGANISATIONS}`, data)

        const locs = []

        for (const json of resp.data.data) {
            locs.push(Organisation.fromJSON(json))
        }
        resp.data.data = locs

        return resp.data
    },
    upcomingEvents: async function (slug: string, page: number = 1, perPage: number = 10) {
        const resp = await AuthService.get(`${ORGANISATIONS}/${slug}/upcoming-events`, {
            page: page,
            per_page: perPage,
        })

        const events = []
        for (const json of resp.data.data) {
            events.push(Event.fromJSON(json))
        }
        resp.data.events = events
        return resp.data
    },
}
export default OrganisationService

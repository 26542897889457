import PendingImage from 'models/PendingImage'
import Workshop from 'models/Workshop'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ImageService from 'services/ImageService'
import WorkshopService from './WorkshopService'

export function useWorkshopEdit(workshopId: string) {
    const [workshop, setWorkshop] = useState<Workshop | null>(null)
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [errors, setErrors] = useState<Record<string, any>>({})
    const history = useHistory()

    useEffect(() => {
        const loadWorkshop = async () => {
            setLoading(true)
            try {
                if (workshopId === 'new') {
                    setWorkshop(new Workshop())
                } else {
                    const ws = await WorkshopService.get(workshopId)
                    if (!ws.hasRight('FULL')) history.push(ws.$detailUrl)
                    setWorkshop(ws)
                }
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadWorkshop()
    }, [workshopId, history])

    const validate = () => {
        const _errors = {}
        const defaultMsg = 'Verplicht veld!'

        _errors['name'] = !workshop.$name ? defaultMsg : false
        _errors['organisation'] = !workshop.$organisation ? defaultMsg : false

        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        if (!workshop) return
        setSaving(true)
        try {
            if (!validate()) return

            // Upload logo if it's a pending image
            if (workshop.$logo instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: workshop.$logo.$file,
                    name: `${workshop.$name} - LOGO`,
                    type: 'LOGO',
                })
                workshop.$logo = image
            }

            // Upload banner if it's a pending image
            if (workshop.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: workshop.$banner.$file,
                    name: `${workshop.$name} - BANNER`,
                    type: 'BANNER',
                })
                workshop.$banner = image
            }

            if (workshopId === 'new') {
                const savedWorkshop = await WorkshopService.post(workshop)
                history.push(savedWorkshop.$detailUrl)
            } else {
                const updatedWorkshop = await WorkshopService.update(workshop)
                history.push(updatedWorkshop.$detailUrl)
            }
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    return {
        workshop,
        loading,
        saving,
        errors,
        validate,
        save,
    }
}

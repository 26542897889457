import { List } from '@mui/material'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { PlusIcon } from 'components/UI/CKIcons'
import CustomLineupSpot from 'models/CustomLineupSpot'
import { FC, useEffect, useState } from 'react'
import CustomLineupSpotEditCard from './CustomLineupSpotEditCard'
import { CustomLineupSpotItem } from './CustomLineupSpotItem'

export interface ICustomLineup {
    lineup: CustomLineupSpot[] | undefined
    onLineupChange?: (lineup: CustomLineupSpot[]) => void
    errors?: Record<string, any>
}

export const CustomLineup: FC<ICustomLineup> = ({
    lineup: defaultLineup,
    onLineupChange,
    errors,
}) => {
    const [lineup, setLineup] = useState<CustomLineupSpot[]>(defaultLineup || [])
    const [openedSpot, setOpenedSpot] = useState<CustomLineupSpot | null>(null)

    useEffect(() => {
        if (onLineupChange) {
            onLineupChange(lineup)
        }
    }, [lineup, onLineupChange])

    const addNewSpot = () => {
        const newSpot = new CustomLineupSpot()
        setLineup((prevLineup) => [...prevLineup, newSpot])
        setOpenedSpot(newSpot)
    }

    const removeSpot = (spot: CustomLineupSpot) => {
        setLineup((prevLineup) => prevLineup.filter((value) => value !== spot))
        setOpenedSpot(null)
    }

    if (openedSpot) {
        return (
            <>
                <MKButton color="info" className="mb-4" onClick={() => setOpenedSpot(null)}>
                    <ArrowLeftIcon />
                    &nbsp; Terug naar lineup
                </MKButton>
                <CustomLineupSpotEditCard
                    customLineupSpot={openedSpot}
                    errors={errors?.[openedSpot.$id]}
                />
            </>
        )
    }

    return (
        <MKBox>
            <MKTypography variant="body2" className="mb-2">
                Voeg spots toe aan de lineup waarvoor er geen bestaand account is. Deze zullen
                worden toegevoegd aan de complete lineup van het evenement.
            </MKTypography>
            <MKBox display="flex" justifyContent="center">
                <MKButton variant="gradient" color="info" onClick={addNewSpot}>
                    <PlusIcon /> Nieuw
                </MKButton>
            </MKBox>
            <MKBox mt={4}>
                <List>
                    {Array.isArray(lineup) &&
                        lineup.map((spot) => (
                            <CustomLineupSpotItem
                                key={spot.$id}
                                spot={spot}
                                error={errors?.[spot.$id]}
                                onRemove={removeSpot}
                                onSelect={setOpenedSpot}
                            />
                        ))}
                </List>
            </MKBox>
        </MKBox>
    )
}

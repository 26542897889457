import { CKLoading } from "components/Generic/CKLoading"
import { CKHero } from "components/UI/CKHero"
import { UserIcon } from "components/UI/CKIcons"
import { CKPageBadge, CKPageContent } from "components/UI/CKPageContent"
import User from "models/User"
import bgImage from 'assets/images/user_banner_default.jpg'

export interface IUserPageContainer {
    user: User
    children: any
    loading: boolean
}

export const UserPageContainer = ({ user, loading, children }: IUserPageContainer) => {
    return (
        <>
            <CKHero size="md" bgImage={user && user.$banner ? user.$bannerUrl : bgImage} />
            <CKPageContent>
                {loading ? (
                    <CKLoading />
                ) : (
                    <>
                        {user && user.$picture && user.$pictureUrl ? (
                            <CKPageBadge src={user.$pictureUrl}></CKPageBadge>
                        ) : (
                            <CKPageBadge>
                                <UserIcon fontSize="large" style={{ color: 'white' }} />\
                            </CKPageBadge>
                        )}
                        {children}
                    </>
                )}
            </CKPageContent>
        </>
    )
}
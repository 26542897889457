import { Grid } from '@mui/material'
import CKEditImageField from 'components/Form/CKEditImageField'
import Workshop from 'models/Workshop'
import { FC } from 'react'

interface Props {
    workshop: Workshop
}

const WorkshopImageForm: FC<Props> = ({ workshop }) => {
    return (
        <Grid container spacing={3}>
            <CKEditImageField
                xs={12}
                md={4}
                label="Logo"
                fieldName="logo"
                getValue={() => workshop.$logo}
                setValue={(value) => {
                    workshop.$logo = value
                }}
                model={workshop}
                urlValue={() => workshop.$logoUrl}
            />
            <CKEditImageField
                xs={12}
                md={8}
                label="Banner"
                fieldName="banner"
                getValue={() => workshop.$banner}
                setValue={(value) => {
                    workshop.$banner = value
                }}
                model={workshop}
                urlValue={() => workshop.$bannerUrl}
            />
        </Grid>
    )
}

export default WorkshopImageForm 
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OrganisationService from './OrganisationService'
import { useOrganisation } from './UseOrganisation'
import Organisation from 'models/Organisation'
import ImageService from 'services/ImageService'
import PendingImage from 'models/PendingImage'

export function useOrganisationEdit(slug: string) {
    const [organisation, setOrganisation] = useState<Organisation | null>(null)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<any>({})
    const [saving, setSaving] = useState<boolean>(false)
    const history = useHistory()
    const { mutate } = useOrganisation(slug)

    // Load organisation data
    useEffect(() => {
        const loadOrganisation = async () => {
            if (!slug) return
            setLoading(true)
            try {
                if (slug === 'new') {
                    setOrganisation(new Organisation())
                } else {
                    const org = await OrganisationService.get(slug)
                    setOrganisation(org)
                }
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadOrganisation()
    }, [slug])

    const validate = () => {
        const _errors = {}
        _errors['name'] = !organisation?.$name ? 'Verplicht veld!' : false
        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        if (!organisation) return
        setSaving(true)
        try {
            if (!validate()) return

            // Upload logo if it's a pending image
            if (organisation.$logo instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: organisation.$logo.$file,
                    name: `${organisation.$name} - LOGO`,
                    type: 'LOGO',
                })
                organisation.$logo = image
            }

            // Upload banner if it's a pending image
            if (organisation.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: organisation.$banner.$file,
                    name: `${organisation.$name} - BANNER`,
                    type: 'BANNER',
                })
                organisation.$banner = image
            }
            
            if (slug === 'new') {
                const org = await OrganisationService.post(organisation)
                history.push(org.$detailUrl)
            } else {
                const updatedOrganisation = await OrganisationService.update(organisation)
                history.push(updatedOrganisation.$detailUrl)
            }
            mutate()
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    return {
        organisation,
        loading,
        saving,
        errors,
        save
    }
} 
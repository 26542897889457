import DomainAddIcon from '@mui/icons-material/DomainAdd'
import bgImage from 'assets/images/bar_sidebar.jpg'
import CKDeleteModelButton from 'components/Buttons/CKDeleteModelButton'
import CKEditModelButton from 'components/Buttons/CKEditModelButton'
import { CKElevatedUsersMenuItem, CKElevatedUsersModal } from 'components/Buttons/CKElevatedUsers'
import CKSettingsButton from 'components/Buttons/CKSettingsButton'
import CKShareButtons from 'components/Buttons/CKShareButtons'
import MKBox from 'components/MaterialKit/MKBox'
import CKEventList from 'components/UI/CKEventList'
import { CKHero } from 'components/UI/CKHero'
import CKLoadingModel from 'components/UI/CKLoadingModel'
import { CKPageBadge, CKPageBody, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import CKSocials from 'components/UI/CKSocials'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import OrganisationService from 'services/Organisation/OrganisationService'
import { useOrganisation } from 'services/Organisation/UseOrganisation'

interface Props { }

const PageOrganisationDetail: FC<Props> = () => {
    const organisationId = useParams()['organisationId']
    const {organisation, isLoading: loading} = useOrganisation(organisationId)
    const [elevatedUsersOpen, setElevatedUsersOpen] = useState(false)

    const banner = useMemo(() => {
        if (!organisation || !organisation.$banner || !organisation.$bannerUrl) return bgImage

        return organisation.$bannerUrl
    }, [organisation])

    return (
        <>
            {organisation ? (
                <MKBox>
                    <CKHero size="md" bgImage={banner} />
                    <CKPageContent>
                        <CKPageBadge src={organisation.$logoUrl}>
                            <DomainAddIcon fontSize="large" style={{ color: 'white' }} />
                        </CKPageBadge>
                        <CKPageTitle>
                            {organisation.$name}
                        </CKPageTitle>
                        <div className="text-center mt-3">
                            <CKShareButtons />
                            <CKSettingsButton hideWhenEmpty className="ms-4">
                                {organisation.hasRight('FULL') && (
                                    <CKEditModelButton
                                        className="h-100 w-100"
                                        model={organisation}
                                        menuItem
                                    />
                                )}
                                {organisation.isOwner() && (
                                    <CKElevatedUsersMenuItem
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setElevatedUsersOpen(true)
                                        }}
                                    />
                                )}
                                {organisation.isOwner() && (
                                    <CKDeleteModelButton
                                        className="h-100 w-100"
                                        model={organisation}
                                    />
                                )}
                            </CKSettingsButton>
                            <CKElevatedUsersModal
                                model={organisation}
                                open={elevatedUsersOpen}
                                onClose={() => setElevatedUsersOpen(false)}
                            />
                        </div>
                        <CKPageBody center>
                            <div className="mx-auto text-center my-3" style={{ maxWidth: '700px' }}>
                                {organisation.$description}
                            </div>
                            <MKBox mt={3}>
                                <CKSocials socials={organisation.$socials} />
                            </MKBox>
                            <MKBox mt={4}>
                                <CKEventList
                                    model={organisation}
                                    upcomingEventsFunction={OrganisationService.upcomingEvents}
                                />
                            </MKBox>
                        </CKPageBody>
                    </CKPageContent>
                </MKBox>
            ) : (
                <CKLoadingModel notFoundMessage="Geen organisatie gevonden" loading={loading} />
            )}
        </>
    )
}
export default PageOrganisationDetail

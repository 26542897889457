import { DEFAULT_WORKSHOP_BANNER, DEFAULT_WORKSHOP_LOGO } from 'constants/images'
import CKModel from './CKModel'
import Image from './Image'
import Organisation from './Organisation'
import PendingImage from './PendingImage'
import Social from './Social'
import User from './User'
import { Banner, Logo } from './interfaces/Images'
import { EditableImage } from './types/ImageTypes'

export default class Workshop extends CKModel implements Logo, Banner {
    private description: string | undefined

    private registration_start_time: Date | undefined
    private registration_end_time: Date | undefined

    private organisation: Organisation | undefined
    private organiser: Organisation | User | undefined

    private logo: EditableImage
    private banner: EditableImage

    private banner_id: string | undefined
    private logo_id: string | undefined
    private organisation_id: string | undefined

    constructor(
        id: string | undefined = undefined,
        slug: string | undefined = undefined,
        name: string | undefined = undefined,
        description: string | undefined = undefined,
        logo: Image | undefined = undefined,
        banner: Image | undefined = undefined,
        socials: Social[] = []
    ) {
        super(id, slug, socials)

        this.name = name
        this.description = description
        this.logo = logo
        this.banner = banner
    }

    public static fromJSON(json: any) {
        let result = new Workshop()
        result = Object.assign(result, json)

        if (json.registration_start_time) {
            result.$registration_start_time = new Date(json.registration_start_time)
        }

        if (json.registration_end_time) {
            result.$registration_end_time = new Date(json.registration_end_time)
        }

        if (json.logo) {
            result.$logo = Image.fromJSON(json.logo)
        }

        if (json.banner) {
            result.$banner = Image.fromJSON(json.banner)
        }

        if (json.socials) {
            const socials: Social[] = []
            for (const social of json.socials) {
                socials.push(Social.fromJSON(social))
            }
            result.$socials = socials
        }

        return result
    }

    public get $description(): string | undefined {
        return this.description
    }
    public set $description(value: string | undefined) {
        this.description = value
    }

    public get $registration_start_time(): Date | undefined {
        return this.registration_start_time
    }
    public set $registration_start_time(value: Date | undefined) {
        this.registration_start_time = value
    }

    public get $registration_end_time(): Date | undefined {
        return this.registration_end_time
    }
    public set $registration_end_time(value: Date | undefined) {
        this.registration_end_time = value
    }

    /**
     * Logo
     */
    public get $logo(): EditableImage {
        return this.logo
    }
    public set $logo(value: EditableImage) {
        this.logo = value
        if (value instanceof Image) {
            this.logo_id = value.$id
        } else {
            this.logo_id = undefined
        }
    }
    public get $logoUrl(): string | undefined {
        if (this.$logo instanceof Image) return this.$logo.$url
        if (this.$logo instanceof PendingImage) return this.$logo.$previewUrl
        return DEFAULT_WORKSHOP_LOGO
    }

    /**
     * Banner
     */
    public get $banner(): EditableImage {
        return this.banner
    }
    public set $banner(value: EditableImage) {
        this.banner = value
        if (value instanceof Image) {
            this.banner_id = value.$id
        } else {
            this.banner_id = undefined
        }
    }
    public get $bannerUrl(): string | undefined {
        if (this.$banner instanceof Image) return this.$banner.$url
        if (this.$banner instanceof PendingImage) return this.$banner.$previewUrl
        return DEFAULT_WORKSHOP_BANNER
    }

    public get $organiser(): Organisation | User | undefined {
        return this.organiser
    }
    public set $organiser(value: Organisation | User | undefined) {
        this.organiser = value
    }

    public get $organisation(): Organisation | undefined {
        return this.organisation
    }
    public set $organisation(value: Organisation | undefined) {
        this.organisation = value
        this.organisation_id = value.$id
    }

    public get $className(): string {
        return 'workshop'
    }
}

import CKSelect from 'components/Form/CKSelect'
import { useUserContext } from 'contexts/UserContext'
import Organisation from 'models/Organisation'
import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useManagedOrganisations } from 'services/UserService'

interface IOrganisationSelect {
    defaultOrganisation?: Organisation
    onChange?: (organisation: Organisation) => void
}

export const CKOrganisationSelect: React.FC<IOrganisationSelect> = ({
    defaultOrganisation,
    onChange,
}) => {
    const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | undefined>(
        defaultOrganisation
    )
    
    const { eventId } = useParams<{ eventId: string }>()
    const { user } = useUserContext()
    const {
        organisations: managedOrganisations,
        default: defaultOrganisator,
        hasOrganisations,
    } = useManagedOrganisations()

    const organisatorOptions = useMemo(() => [
        { value: 'me', label: `Jijzelf (${user?.$name})` },
        ...managedOrganisations.map((org) => ({
            value: org.$id,
            label: org.$name,
        })),
    ], [user?.$name, managedOrganisations])

    const handleOrganisationChange = (id: string | undefined) => {
        if (id === 'me') {
            setSelectedOrganisation(undefined)
            onChange?.(undefined)
            return
        }

        const organisation = id 
            ? managedOrganisations.find((org) => org.$id === id)
            : undefined
            
        setSelectedOrganisation(organisation)
        onChange?.(organisation)
    }

    const getDefaultValue = () => {
        if (selectedOrganisation) {
            return selectedOrganisation.$id
        }
        
        if (eventId === 'new' && organisatorOptions.length >= 2) {
            return organisatorOptions[1].value
        }
        
        return 'me'
    }

    useEffect(() => {
        if (eventId === 'new' && organisatorOptions.length >= 2) {
            const org = managedOrganisations.find(
                (org) => org.$id === organisatorOptions[1].value
            )
            setSelectedOrganisation(org)
            onChange?.(org)
        }
    }, [eventId, organisatorOptions.length, managedOrganisations, onChange, organisatorOptions])

    if (!hasOrganisations || !defaultOrganisator) {
        return null
    }

    return (
        <CKSelect
            options={organisatorOptions}
            className="w-100"
            onSelect={handleOrganisationChange}
            defaultValue={getDefaultValue()}
            label="Organisator"
        />
    )
}

import Event from 'models/Event'
import PendingImage from 'models/PendingImage'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ImageService from 'services/ImageService'
import EventService, { useEvent } from './EventService'

export function useEventEdit(slug: string) {
    const [event, setEvent] = useState<Event | null>(null)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<any>({})
    const [saving, setSaving] = useState<boolean>(false)
    const history = useHistory()
    const { mutate } = useEvent(slug)

    useEffect(() => {
        const loadEvent = async () => {
            if (!slug) return
            setLoading(true)
            try {
                if (slug === 'new') {
                    setEvent(new Event())
                } else {
                    const evt = await EventService.get(slug)
                    setEvent(evt)
                }
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadEvent()
    }, [slug])

    const validate = () => {
        if (!event) return false
        const _errors = {}
        const defaultMsg = 'Verplicht veld!'

        _errors['name'] = !event.$name ? defaultMsg : false
        _errors['date'] = !event.$start_time ? defaultMsg : false
        _errors['start_time'] = !event.$start_time ? defaultMsg : false
        _errors['arrival_time'] = !event.$arrival_time ? defaultMsg : false
        if (event.$start_time && event.$arrival_time && event.$start_time < event.$arrival_time) {
            _errors['arrival_time'] = 'Ongeldige aankomsttijd'
        }
        _errors['location'] = !event.$location ? defaultMsg : false

        // Add validation for custom lineup spots
        if (event.$custom_lineup?.length > 0) {
            const customLineupErrors = {}
            event.$custom_lineup.forEach((spot) => {
                const spotErrors = spot.validate()
                if (Object.values(spotErrors).some((error) => error !== false)) {
                    customLineupErrors[spot.$id] = spotErrors
                }
            })
            if (Object.keys(customLineupErrors).length > 0) {
                _errors['custom_lineup'] = customLineupErrors
            }
        }

        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        if (!event) return
        setSaving(true)
        try {
            if (!validate()) return

            // Upload logo if it's a pending image
            if (event.$logo instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: event.$logo.$file,
                    name: `${event.$name} - LOGO`,
                    type: 'LOGO',
                })
                event.$logo = image
            }

            // Upload banner if it's a pending image
            if (event.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: event.$banner.$file,
                    name: `${event.$name} - BANNER`,
                    type: 'BANNER',
                })
                event.$banner = image
            }

            // Handle custom lineup images
            if (event.$custom_lineup) {
                for (const spot of event.$custom_lineup) {
                    await spot.uploadPendingImage()
                }
            }

            if (slug === 'new') {
                const savedEvent = await EventService.post(event)
                history.push(savedEvent.$detailUrl)
            } else {
                const updatedEvent = await EventService.update(event)
                history.push(updatedEvent.$detailUrl)
            }
            mutate()
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    return {
        event,
        loading,
        saving,
        errors,
        save,
    }
}

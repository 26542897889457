import { Box, List } from '@mui/material'
import { CKLoading } from 'components/Generic/CKLoading'
import MKButton from 'components/MaterialKit/MKButton'
import CKNotification from 'components/UI/CKNotification'
import { FC, useEffect } from 'react'
import { NotificationPageContainer } from './NotificationPageContainer'
import { useNotificationsContext } from 'contexts/NotificationsContext'

const PageNotifications: FC = () => {
    const { notifications, isLoading, hasMorePages, loadMore, refresh, unreadCount } = useNotificationsContext()

    useEffect(() => {
        // Refresh when component mounts or when unreadCount changes
        refresh()
    }, [refresh, unreadCount])

    return (
        <NotificationPageContainer loading={isLoading}>
            <List disablePadding>
                {notifications.map((notification) => (
                    <CKNotification 
                        key={notification.$id} 
                        notification={notification} 
                    />
                ))}
            </List>
            {isLoading ? (
                <CKLoading />
            ) : (
                hasMorePages && (
                    <Box className="d-flex justify-content-center" sx={{ mt: 2 }}>
                        <MKButton 
                            color="info" 
                            onClick={loadMore} 
                            disabled={isLoading}
                        >
                            Oudere meldingen laden
                        </MKButton>
                    </Box>
                )
            )}
        </NotificationPageContainer>
    )
}

export default PageNotifications

import ChecklistIcon from '@mui/icons-material/Checklist'
import { FormGroup, Grid, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKButton from 'components/MaterialKit/MKButton'
import {
    CKModal,
    CKModalBadge,
    CKModalHeader,
    CKModalSubtitle,
    CKModalTitle,
} from 'components/UI/CKModal'
import CKModalBody from 'components/UI/CKModal/CKModalBody'
import Event from 'models/Event'
import User from 'models/User'
import React, { FC, useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import EventService from 'services/Event/EventService'
import './style.scss'

// Icons
import ClearIcon from '@mui/icons-material/Clear'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import SaveIcon from '@mui/icons-material/Save'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import CKCheckBox from 'components/Form/CKCheckBox'
import CKSearchBar from 'components/Form/CKSearchBar'
import EventSpot from 'models/EventSpot'

interface Props {
    event: Event
    className?: string
    menuItem?: boolean
    [rest: string]: any
}
const style = {}

const CKLineupEditButton: FC<Props> = ({ event, className, menuItem, ...rest }) => {
    const [open, setOpen] = useState(false)
    const [users, setUsers] = useState<User[]>()
    const [searchTerm, setSearchTerm] = useState('')
    const [selection, setSelection] = useState({})
    const [saving, setSaving] = useState(false)
    const [isComplete, setIsComplete] = useState(event.$lineupComplete)

    const fetchUsers = async (page = 1, search = searchTerm) => {
        const _users = await EventService.getApplications(event, page, search)
        setUsers(_users)
    }

    const init = async () => {
        fetchUsers()
        const _selection = {}
        for (const spot of event.$spots) {
            _selection[spot.$id] = []
        }

        if (event.$lineUp) {
            for (const user of event.$lineUp) {
                _selection[user.pivot.event_spot_id].push(user)
            }
        }

        setSelection(_selection)
    }

    const isSelected = (spot: EventSpot, user: User) => {
        const result = selection[spot.$id].find((a) => a.$id === user.$id)
        return result ? true : false
    }

    const save = async () => {
        setSaving(true)

        try {
            const data = []
            event.$lineUp = []
            for (const spot of event.$spots) {
                for (const user of selection[spot.$id]) {
                    data.push({
                        event_spot_id: spot.$id,
                        user_id: user.$id,
                    })

                    user.pivot = {
                        event_spot_id: spot.$id,
                    }
                    event.$lineUp.push(user)
                }
            }
            await EventService.saveLineup(event, isComplete, data)
        } catch (err) {
            console.error(err)
        }

        setSaving(false)
    }

    useEffect(() => {
        init()
    }, [])

    const searchBox = () => {
        return (
            <div>
                <CKSearchBar
                    defaultValue={searchTerm}
                    onSearch={(e) => {
                        setSearchTerm(e)
                        fetchUsers(1, e)
                    }}
                />
            </div>
        )
    }

    const validate = (_selection) => {
        let result = true
        for (const spot of event.$spots) {
            if (_selection[spot.$id].length < spot.$amount) {
                result = false
            }
        }
        setIsComplete(result)
    }

    const changeSelection = (spot: EventSpot, user: User) => {
        const _selection = Object.assign({}, selection)
        if (isSelected(spot, user)) {
            const index = _selection[spot.$id].findIndex((a) => a.$id === user.$id)
            _selection[spot.$id].splice(index, 1)
        } else {
            _selection[spot.$id].push(user)
        }

        for (const _spot of event.$spots) {
            if (_spot.$id !== spot.$id && isSelected(_spot, user)) {
                const index = _selection[_spot.$id].findIndex((a) => a.$id === user.$id)
                _selection[_spot.$id].splice(index, 1)
            }
        }

        setSelection(_selection)
        validate(_selection)
    }

    const shuffle = () => {
        const applications = {}
        for (const spot of event.$spots) {
            if (!applications[spot.$id]) {
                applications[spot.$id] = []
            }

            for (const user of users) {
                if (user.$applications.findIndex((a) => a.$id === spot.$id) >= 0) {
                    applications[spot.$id].push(user)
                }
            }
        }

        const order = Object.keys(applications).sort((a, b) => {
            if (applications[a].length > applications[b].length) return 1
            else return -1
        })

        const chosen: User[] = []
        const result = {}
        for (const spotId of order) {
            result[spotId] = []
            const amount = Math.min(
                applications[spotId].length,
                event.$spots.find((a) => a.$id === spotId).$amount
            )
            let stop = amount === 0
            while (!stop) {
                const index = Math.round(Math.random() * (applications[spotId].length - 1))
                const user = applications[spotId][index]
                if (chosen.findIndex((a) => a.$id === user.$id) >= 0) {
                    continue
                }

                chosen.push(user)
                result[spotId].push(user)
                stop = result[spotId].length === amount
            }
        }
        setSelection(result)
        validate(result)
    }

    const renderUserRow = (user) => {
        return (
            <React.Fragment key={'user-row-' + user.$id}>
                <Grid item xs={12 - event.$spots.length * 2}>
                    <div className="d-flex align-items-center ">
                        <MKAvatar
                            src={user.$picture && user.$pictureUrl}
                            size="sm"
                            className="me-2"
                        />
                        <p className="ck-lineup-user-name">
                            {user.$first_name} {user.$last_name}
                        </p>
                    </div>
                </Grid>
                {event.$spots &&
                    event.$spots.map((spot, index) => {
                        return (
                            <Grid item xs={2} key={'spot-buttons-' + user.$id + '-' + index}>
                                {user.$applications &&
                                    user.$applications.findIndex((a) => a.$id === spot.$id) >=
                                        0 && (
                                        <div
                                            className="ck-lineup-toggle w-100 text-center"
                                            onClick={() => {
                                                changeSelection(spot, user)
                                            }}
                                        >
                                            {isSelected(spot, user) ? (
                                                <RadioButtonCheckedIcon
                                                    color="info"
                                                    fontSize="large"
                                                />
                                            ) : (
                                                <RadioButtonUncheckedIcon
                                                    color="info"
                                                    fontSize="large"
                                                />
                                            )}
                                        </div>
                                    )}
                            </Grid>
                        )
                    })}
            </React.Fragment>
        )
    }

    const renderButton = () => {
        className = (className && className) + ' ck-edit-button'
        return (
            <>
                {menuItem ? (
                    <MenuItem
                        onClick={(e) => {
                            setOpen(true)
                        }}
                    >
                        <ListItemIcon>
                            <ChecklistIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Line-up beheer</ListItemText>
                    </MenuItem>
                ) : (
                    <MKButton
                        variant="gradient"
                        color="info"
                        className={className}
                        onClick={(e) => {
                            setOpen(true)
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <ChecklistIcon className="me-2 ck-edit-icon" fontSize="large" />
                            <p className="m-0 p-0">Line-up beheer</p>
                        </div>
                    </MKButton>
                )}
            </>
        )
    }

    return (
        <>
            {renderButton()}
            <CKModal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                closeButton
            >
                <CKModalHeader className="mb-3">
                    <CKModalBadge src={event.$logo && event.$logoUrl} />
                    <CKModalTitle>{event.$name}</CKModalTitle>
                    <CKModalSubtitle>Line-Up</CKModalSubtitle>
                </CKModalHeader>
                <CKModalBody>
                    {searchBox()}
                    <div className="px-2 py-3">
                        <Grid container spacing={2} className="mb-2">
                            <Grid item xs={12 - event.$spots.length * 2}>
                                <MKButton
                                    variant="outlined"
                                    color="info"
                                    onClick={(e) => {
                                        shuffle()
                                    }}
                                >
                                    <ShuffleIcon className="me-2" />
                                    Willekeurige selectie
                                </MKButton>
                            </Grid>
                            {event.$spots &&
                                Array.from(event.$spots)
                                    .sort((a, b) => {
                                        return a.$length - b.$length
                                    })
                                    .map((spot, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={2}
                                                key={'spot-column-' + index}
                                                className="ck-table-head text-center"
                                            >
                                                {spot.$type === 'MC' ? (
                                                    <div
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            textAlign: 'center',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        MC
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            textAlign: 'center',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {spot.$length} min.
                                                    </div>
                                                )}
                                                <div
                                                    className="text-center w-100"
                                                    style={{
                                                        fontSize: '0.7rem',
                                                    }}
                                                >
                                                    (
                                                    {selection[spot.$id]
                                                        ? selection[spot.$id].length
                                                        : 0}{' '}
                                                    / {spot.$amount})
                                                </div>
                                            </Grid>
                                        )
                                    })}
                        </Grid>
                        <PerfectScrollbar style={{ height: '50vh' }}>
                            <Grid container spacing={2}>
                                {users &&
                                    users.map((user, index) => {
                                        return renderUserRow(user)
                                    })}
                            </Grid>
                        </PerfectScrollbar>
                    </div>
                    <div className="text-center mb-2">
                        <FormGroup>
                            <CKCheckBox
                                label="Markeer als compleet"
                                checked={isComplete}
                                onChange={(e) => {
                                    setIsComplete(e.target.checked)
                                }}
                                name="lineup complete"
                            />
                        </FormGroup>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <MKButton
                                variant="gradient"
                                color="info"
                                className="w-100"
                                onClick={async (e) => {
                                    await save()
                                    init()
                                    setOpen(false)
                                }}
                            >
                                <SaveIcon className="me-2" />
                                Opslaan
                            </MKButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MKButton
                                variant="outlined"
                                color="info"
                                className="w-100"
                                onClick={(e) => {
                                    init()
                                    setOpen(false)
                                }}
                            >
                                <ClearIcon className="me-2" />
                                Annuleren
                            </MKButton>
                        </Grid>
                    </Grid>
                </CKModalBody>
            </CKModal>
        </>
    )
}
export default CKLineupEditButton

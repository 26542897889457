import { Grid } from '@mui/material'
import MKInput from 'components/MaterialKit/MKInput'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import { CKOrganisationSelect } from 'components/Form/CKOrganisationSelect'
import Workshop from 'models/Workshop'
import { FC } from 'react'

interface Props {
    workshop: Workshop
    errors: Record<string, any>
}

const GeneralForm: FC<Props> = ({ workshop, errors }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MKInput
                    label="Naam"
                    error={errors['name'] ? true : false}
                    title="Naam"
                    defaultValue={workshop.$name}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                        workshop.$name = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['name']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12}>
                <MKInput
                    multiline
                    defaultValue={workshop.$description ?? ""}
                    placeholder="Geef een beschrijving van de workshop..."
                    title="Beschrijving"
                    label="Beschrijving"
                    variant="outlined"
                    onChange={(e) => {
                        workshop.$description = e.target.value
                    }}
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CKOrganisationSelect
                    defaultOrganisation={workshop.$organisation}
                    onChange={(org) => {
                        workshop.$organisation = org
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default GeneralForm 
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Location from 'models/Location'
import LocationService from './LocationService'
import PendingImage from 'models/PendingImage'
import ImageService from 'services/ImageService'

export function useLocationEdit(locationId: string) {
    const [location, setLocation] = useState<Location | null>(null)
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [errors, setErrors] = useState<Record<string, any>>({})
    const history = useHistory()

    useEffect(() => {
        const loadLocation = async () => {
            setLoading(true)
            try {
                if (locationId === 'new') {
                    setLocation(new Location())
                } else {
                    const loc = await LocationService.get(locationId)
                    if (!loc.hasRight('FULL')) history.push(loc.$detailUrl)
                    setLocation(loc)
                }
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadLocation()
    }, [locationId, history])

    const validate = () => {
        const _errors = {}
        const defaultMsg = 'Verplicht veld!'

        _errors['name'] = !location.$name ? defaultMsg : false
        _errors['location'] =
            !location.$address || !location.$address.$lat || !location.$address.$lng
                ? defaultMsg
                : false

        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        if (!location) return
        setSaving(true)
        try {
            if (!validate()) return

            // Upload logo if it's a pending image
            if (location.$logo instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: location.$logo.$file,
                    name: `${location.$name} - LOGO`,
                    type: 'LOGO',
                })
                location.$logo = image
            }

            // Upload banner if it's a pending image
            if (location.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: location.$banner.$file,
                    name: `${location.$name} - BANNER`,
                    type: 'BANNER',
                })
                location.$banner = image
            }

            if (locationId === 'new') {
                const savedLocation = await LocationService.post(location)
                history.push(savedLocation.$detailUrl)
            } else {
                const updatedLocation = await LocationService.update(location)
                history.push(updatedLocation.$detailUrl)
            }
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    return {
        location,
        loading,
        saving,
        errors,
        save,
    }
} 
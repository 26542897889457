import { Badge, Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import clsx from 'clsx'
import { CKEventRow } from 'components/UI/CKEventRow'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/nl'
import Event from 'models/Event'
import { FC, useEffect, useState } from 'react'
import UserService from 'services/UserService'
import './style.scss'

dayjs.locale('nl')

interface Props {}

const CKCalendar: FC<Props> = (props) => {
    const theme = useTheme()

    const [loading, setLoading] = useState(false)
    const [highlightedDays, setHighlightedDays] = useState([])
    const [initialValue, setInitialValue] = useState<Dayjs>()
    const [events, setEvents] = useState<Event[]>()
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

    useEffect(() => {
        const day = dayjs()
        setInitialValue(day)

        handleMonthChange(day)
    }, [])

    const handleMonthChange = async (date: Dayjs) => {
        setLoading(true)
        const _events = await UserService.getCalendar(date.month() + 1, date.year())

        const _days = _events.map((a) => {
            return a.$start_time.getDate()
        })

        setHighlightedDays(_days)
        setEvents(_events)
        setLoading(false)
    }

    function RenderDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props

        const isSelected =
            !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0

        let icon = undefined
        if (isSelected) {
            const url = events.find((a) => a.$start_time.getDate() === props.day.date()).$logoUrl
            icon = (
                <img
                    src={url}
                    width="25px"
                    height={'25px'}
                    style={{
                        borderRadius: '50%',
                        marginLeft: '-0.1rem',
                        marginTop: '0.1rem',
                    }}
                />
            )
        }

        return (
            <Badge key={props.day.toString()} overlap="circular" badgeContent={icon}>
                <PickersDay
                    {...other}
                    className={clsx('ck-calendar-day', {
                        'ck-event-day': isSelected,
                    })}
                    outsideCurrentMonth={outsideCurrentMonth}
                    day={day}
                />
            </Badge>
        )
    }

    return (
        <Grid container className="d-flex justify-content-center my-2">
            {initialValue && (
                <>
                    <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-end align-items-center mb-5"
                    >
                        <div className="ck-calendar-container">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    className="ck-my-calendar"
                                    value={initialValue}
                                    onChange={(e) => {
                                        setInitialValue(e)
                                    }}
                                    loading={loading}
                                    onMonthChange={handleMonthChange}
                                    renderLoading={() => <DayCalendarSkeleton />}
                                    slots={{
                                        day: RenderDay,
                                    }}
                                    slotProps={{
                                        day: {
                                            highlightedDays,
                                        } as any,
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={clsx(
                            'd-flex flex-column justify-content-center align-items-start',
                            { 'pt-3': !isLarge }
                        )}
                    >
                        {events && highlightedDays.includes(initialValue.date()) ? (
                            <>
                                {events &&
                                    events
                                        .filter(
                                            (a) => a.$start_time.getDate() === initialValue.date()
                                        )
                                        .map((event, index) => {
                                            return (
                                                <div
                                                    className={clsx({ 'px-3': isLarge }, 'mx-auto')}
                                                    key={'event-' + event.$id}
                                                >
                                                    <CKEventRow event={event} compact={false} />
                                                </div>
                                            )
                                        })}
                            </>
                        ) : (
                            <div
                                className={clsx(
                                    'd-flex justify-content-center align-items-center w-100',
                                    { 'pt-3': !isLarge }
                                )}
                            >
                                Geen evenementen op de geselecteerde dag
                            </div>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    )
}
export default CKCalendar

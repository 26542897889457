import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Card, CardMedia, useMediaQuery } from '@mui/material'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import Location from 'models/Location'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'

interface Props {
    location: Location;
    useAdminUrl?: boolean;
}
const CKLocationCompactCard: FC<Props> = (props) => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const history = useHistory()

    return (
        <>
            <MKBox
                mb={isMobile ? 2 : 1}
                className="ck-location-compact-container"
                onClick={() => {
                    if (props.useAdminUrl) {
                        history.push(props.location.$adminUrl)
                    } else {
                        history.push(props.location.$detailUrl)
                    }
                }}
            >
                <Card>
                    <CardMedia
                        className="ck-location-compact-banner"
                        image={props.location.$bannerUrl}
                    />
                    <MKAvatar
                        src={props.location.$logoUrl}
                        className="ck-location-compact-badge"
                        size="lg"
                        shadow="xl"
                    />
                    <MKTypography variant="h5" my={1} className={'event-card-title text-center'}>
                        {props.location.$name}
                    </MKTypography>
                    <div className="mt-2">
                        <MKTypography
                            variant="h6"
                            color={'info'}
                            className="mx-auto d-flex align-items-center justify-content-center"
                            style={{ maxWidth: '80%' }}
                            component="div"
                        >
                            <LocationOnIcon />
                        </MKTypography>
                        <MKTypography
                            variant="h6"
                            color={'info'}
                            className="m-auto my-2 d-flex align-items-center justify-content-center"
                            style={{ maxWidth: '80%' }}
                            component="div"
                        >
                            <div>
                                {props.location.$address.$street}{' '}
                                {props.location.$address.$streetNumber}
                                {props.location.$address.$city && (
                                    <div> {props.location.$address.$city} </div>
                                )}
                            </div>
                        </MKTypography>
                    </div>
                </Card>
            </MKBox>
        </>
    )
}
export default CKLocationCompactCard

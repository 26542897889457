import { DEFAULT_LOCATION_BANNER, DEFAULT_LOCATION_LOGO } from 'constants/images'
import Address from './Address'
import CKModel from './CKModel'
import Image from './Image'
import PendingImage from './PendingImage'
import Social from './Social'
import { Banner, Logo } from './interfaces/Images'
import { EditableImage } from './types/ImageTypes'

export default class Location extends CKModel implements Logo, Banner {
    private address: Address | undefined
    private description: string | undefined

    private logo: EditableImage
    private banner: EditableImage

    private banner_id: string | undefined
    private logo_id: string | undefined

    constructor(
        id: string | undefined = undefined,
        slug: string | undefined = undefined,
        name: string | undefined = undefined,
        address: Address | undefined = undefined,
        description: string | undefined = undefined,
        logo: EditableImage = undefined,
        banner: EditableImage = undefined,
        socials: Social[] = []
    ) {
        super(id, slug, socials)

        this.name = name
        this.address = address
        this.description = description
        this.address = address
        this.logo = logo
        this.banner = banner
    }

    public static fromJSON(json: any) {
        let result = new Location()
        result = Object.assign(result, json)

        if (json.logo) {
            result.$logo = Image.fromJSON(json.logo)
        }

        if (json.banner) {
            result.$banner = Image.fromJSON(json.banner)
        }

        if (json.socials) {
            const socials: Social[] = []
            for (const social of json.socials) {
                socials.push(Social.fromJSON(social))
            }
            result.$socials = socials
        }

        if (json.address) {
            const addr = Address.fromJSON(json.address)
            result.$address = addr
        }

        return result
    }

    public get $description(): string | undefined {
        return this.description
    }
    public set $description(value: string | undefined) {
        this.description = value
    }

    public get $address(): Address | undefined {
        return this.address
    }
    public set $address(value: Address | undefined) {
        this.address = value
    }

    /**
     * Logo
     */
    public get $logo(): EditableImage {
        return this.logo
    }
    public set $logo(value: EditableImage) {
        this.logo = value
        if (value instanceof Image) {
            this.logo_id = value.$id
        } else {
            this.logo_id = undefined
        }
    }
    public get $logoUrl(): string | undefined {
        if (this.$logo instanceof Image) return this.$logo.$url
        if (this.$logo instanceof PendingImage) return this.$logo.$previewUrl
        return DEFAULT_LOCATION_LOGO
    }

    /**
     * Banner
     */
    public get $banner(): EditableImage {
        return this.banner
    }
    public set $banner(value: EditableImage) {
        this.banner = value
        if (value instanceof Image) {
            this.banner_id = value.$id
        } else {
            this.banner_id = undefined
        }
    }
    public get $bannerUrl(): string | undefined {
        if (this.$banner instanceof Image) return this.$banner.$url
        if (this.$banner instanceof PendingImage) return this.$banner.$previewUrl
        return DEFAULT_LOCATION_BANNER
    }

    public get $className(): string {
        return 'location'
    }
}

import { Container, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CKSearchBar from 'components/Form/CKSearchBar'
import { CKLoading } from 'components/Generic/CKLoading'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { WorkshopIcon } from 'components/UI/CKIcons'
import CKWorkshopCard from 'components/UI/CKWorkshopCard/CKWorkshopCompactCard'
import CKPagination from 'components/UI/CKPagination'
import { useUserContext } from 'contexts/UserContext'
import { FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useManagedWorkshops } from 'services/UserService'

interface Props { }
const CKWorkshopManagement: FC<Props> = (props) => {
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
    const [searchTerm, setSearchTerm] = useState('')
    const history = useHistory()
    const userContext = useUserContext()

    const [page, setPage] = useState(1)
    const { workshops, hasWorkshops, paginationInfo, isLoading: loading } = useManagedWorkshops(searchTerm, page)

    const perPage = useMemo(() => {
        if (isLarge) {
            return 8
        }
        return 5
    }, [isLarge])


    const managedWorkshops = () => {
        if (!hasWorkshops) {
            return (
                <div className="w-100 d-flex justify-content-center align-items-center flex-column my-5 py-4 h-100">
                    <MKTypography fontWeight="bold">
                        Geen workshops gevonden...
                    </MKTypography>
                </div>
            )
        }

        return (
            <Grid container className="d-flex justify-content-center">
                {workshops.map((workshop) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            key={'workshop-' + workshop.$slug}
                            className="pt-1 px-2 pt-lg-2 d-flex justify-content-center"
                        >
                            <CKWorkshopCard workshop={workshop} />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    return (
        <>
            {(userContext?.user?.$isOrganizer) && (
                <Container className="d-flex flex-column p-0">
                    <Grid
                        container
                        className="mt-3 justify-content-center"
                    >
                        {loading ? (
                            <CKLoading />
                        ) : (
                            <>
                                <Grid item xs={12} className="text-center">
                                    <MKButton
                                        color="info"
                                        variant="outlined"
                                        onClick={(e) => {
                                            if (e.ctrlKey) {
                                                window.open('admin/workshop/new', '_blank')
                                            } else {
                                                history.push('admin/workshop/new')
                                            }
                                        }}
                                        disabled={true} // TODO: remove this
                                    >
                                        <WorkshopIcon className="me-2" />Workshop aanmaken nog niet mogelijk
                                    </MKButton>
                                    {hasWorkshops && (
                                        <CKSearchBar
                                            className="my-4"
                                            onSearch={(e) => {
                                                setPage(1)
                                                setSearchTerm(e)
                                            }}
                                        />
                                    )}
                                    {managedWorkshops()}
                                </Grid>
                                <CKPagination
                                    info={paginationInfo}
                                    onSelect={setPage}
                                />
                            </>
                        )}
                    </Grid>
                </Container>
            )}
        </>
    )
}
export default CKWorkshopManagement

import Workshop from 'models/Workshop'
import CKIndexProps from 'utils/CKIndexProps'
import AuthService, { addSearchParams, useSWRPaginated } from '../AuthService'
import { WORKSHOPS } from '../Constants'
import useSWR from 'swr'

export function useWorkshop(slug: string) {
    const { data, ...props } = useSWR(`${WORKSHOPS}/${slug}`)
    if (!data) return { workshop: null, ...props }

    const workshop = Workshop.fromJSON(data)
    return {
        workshop,
        ...props,
    }
}

export function useWorkshops(getProps: CKIndexProps) {
    const path = addSearchParams('workshops', getProps)
    const { data, ...props } = useSWRPaginated(path)

    const _workshops = []
    if (data) {
        for (const u of data) {
            _workshops.push(Workshop.fromJSON(u))
        }
    }

    return {
        workshops: _workshops,
        ...props,
    }
}

const WorkshopService = {
    get: async function (slug: string) {
        const resp = await AuthService.get(`${WORKSHOPS}/${slug}`)

        const loc = Workshop.fromJSON(resp.data)
        return loc
    },
    update: async function (workshop: Workshop) {
        const resp = await AuthService.put(`${WORKSHOPS}/${workshop.$slug}`, workshop)

        const loc = Workshop.fromJSON(resp.data)
        return loc
    },
    post: async function (workshop: Workshop) {
        const resp = await AuthService.post(`${WORKSHOPS}`, workshop)

        const loc = Workshop.fromJSON(resp.data)
        return loc
    },
    index: async function (data: CKIndexProps) {
        const resp = await AuthService.get(`${WORKSHOPS}`, data)

        const locs = []

        for (const json of resp.data.data) {
            locs.push(Workshop.fromJSON(json))
        }
        resp.data.data = locs
        return resp.data
    },
    delete: async function (slug: string) {
        const resp = await AuthService.delete(`${WORKSHOPS}/${slug}`)

        return resp.data
    },
}
export default WorkshopService

import Image from 'models/Image'
import AuthService from './AuthService'
import { IMAGES } from './Constants'
interface ImageUploadProps {
    file: File
    name?: string
    description?: string
    type?: string
}
const ImageService = {
    upload: async function ({
        file,
        name = undefined,
        description = undefined,
        type = 'LOGO',
    }: ImageUploadProps): Promise<Image> {
        const reader = new FileReader()
        const base64Promise = new Promise<string>((resolve) => {
            reader.onload = () => resolve(reader.result as string)
            reader.readAsDataURL(file)
        })

        const base64 = await base64Promise
        const extension = file.name.split('.').pop()

        const resp = await AuthService.post(IMAGES, {
            name,
            content: base64,
            description,
            extension,
            type,
        })
        return Image.fromJSON(resp.data)
    },
}
export default ImageService

import { Grid } from '@mui/material'
import CKEditImageField from 'components/Form/CKEditImageField'
import User from 'models/User'
import { FC } from 'react'

interface Props {
    user: User
}

const UserImageForm: FC<Props> = ({ user }) => {
    return (
        <Grid container spacing={3}>
            <CKEditImageField
                xs={12}
                md={4}
                label="Profielfoto"
                fieldName="picture"
                getValue={() => user.$picture}
                setValue={(value) => {
                    user.$picture = value
                }}
                model={user}
                urlValue={() => user.$pictureUrl}
            />
            <CKEditImageField
                xs={12}
                md={8}
                label="Banner"
                fieldName="banner"
                getValue={() => user.$banner}
                setValue={(value) => {
                    user.$banner = value
                }}
                model={user}
                urlValue={() => user.$bannerUrl}
            />
        </Grid>
    )
}

export default UserImageForm 
import { Grid } from '@mui/material'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import Event from 'models/Event'
import User from 'models/User'
import React, { FC, useMemo, useState } from 'react'
import {
    CKModal,
    CKModalBadge,
    CKModalBody,
    CKModalHeader,
    CKModalSubtitle,
    CKModalTitle,
} from '../CKModal'

import ClearIcon from '@mui/icons-material/Clear'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

import CKGrowableScrollbar from 'components/Form/CKGrowableScrollbar'
import CKSearchBar from 'components/Form/CKSearchBar'
import MKButton from 'components/MaterialKit/MKButton'
import EventSpot from 'models/EventSpot'
import UserService from 'services/UserService'

interface Props {
    open: boolean
    onClose: () => void
    event: Event
    onInvite: (selection: { [spotId: number]: User[] }) => void
}
const CKLineupInviteModal: FC<Props> = ({ open, onClose, event, onInvite }) => {
    const [users, setUsers] = useState<User[]>()
    const [selection, setSelection] = useState({})
    const [searchTerm, setSearchTerm] = useState('')
    const [seed] = useState(Math.round(Math.random() * 9999))

    const [paginationInfo, setPaginationInfo] = useState<{
        current_page: number
        total: number
        per_page: number
        last_page: number
        to: number
    }>()

    const init = async (_page: number = 1, _search: string = searchTerm) => {
        if (!seed) return

        const resp = await UserService.getArtists({
            seed: seed,
            page: _page,
            search: _search,
            perPage: 50,
        })
        setUsers(resp.data)
        setPaginationInfo(resp)
    }

    useMemo(() => {
        if (open) {
            const _selection = {}
            for (const spot of event.$spots) {
                _selection[spot.$id] = []
            }

            if (event.$lineUp) {
                for (const user of event.$lineUp) {
                    _selection[user.pivot.event_spot_id].push(user)
                }
            }

            setSelection(_selection)

            init()
        }
    }, [open])

    const changeSelection = (spot: EventSpot, user: User) => {
        const _selection = Object.assign({}, selection)
        if (isSelected(spot, user)) {
            const index = _selection[spot.$id].findIndex((a) => a.$id === user.$id)
            _selection[spot.$id].splice(index, 1)
        } else {
            _selection[spot.$id].push(user)
        }

        for (const _spot of event.$spots) {
            if (_spot.$id !== spot.$id && isSelected(_spot, user)) {
                const index = _selection[_spot.$id].findIndex((a) => a.$id === user.$id)
                _selection[_spot.$id].splice(index, 1)
            }
        }

        setSelection(_selection)
    }

    const isSelected = (spot: EventSpot, user: User) => {
        const result = selection[spot.$id].find((a) => a.$id === user.$id)
        return result ? true : false
    }

    const searchBox = () => {
        return (
            <div>
                <CKSearchBar
                    defaultValue={searchTerm}
                    onSearch={(e) => {
                        setSearchTerm(e)
                        init(1, e)
                    }}
                />
            </div>
        )
    }

    const renderUserRow = (user) => {
        return (
            <React.Fragment key={'user-row-' + user.$id}>
                <Grid item xs={12 - event.$spots.length * 2} className="d-flex align-items-center ">
                    <div className="d-flex align-items-center ">
                        <MKAvatar
                            src={user.$picture && user.$pictureUrl}
                            size="xs"
                            className="me-2"
                        />
                        <p className="ck-lineup-user-name">
                            {user.$first_name} {user.$last_name}
                        </p>
                    </div>
                </Grid>
                {event.$spots &&
                    event.$spots.map((spot, index) => {
                        console.log(spot)
                        return (
                            <Grid item xs={2} key={'spot-buttons-' + user.$id + '-' + index}>
                                <div
                                    className="ck-lineup-toggle w-100 text-center"
                                    onClick={() => {
                                        changeSelection(spot, user)
                                    }}
                                >
                                    {isSelected(spot, user) ? (
                                        <RadioButtonCheckedIcon color="info" fontSize="large" />
                                    ) : (
                                        <RadioButtonUncheckedIcon color="info" fontSize="large" />
                                    )}
                                </div>
                            </Grid>
                        )
                    })}
            </React.Fragment>
        )
    }
    return (
        <CKModal open={open} onClose={onClose} closeButton>
            <CKModalHeader className="">
                <CKModalBadge src={event.$logo && event.$logoUrl} />
                <CKModalTitle>Iemand uitnodigen</CKModalTitle>
                <CKModalSubtitle>{event.$name}</CKModalSubtitle>
            </CKModalHeader>
            <CKModalBody style={{ height: '100vh' }}>
                {searchBox()}

                <div
                    className="px-2 py-3"
                    style={{
                        flex: '1 1 auto',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <Grid container spacing={2} className="mb-2">
                        <Grid item xs={12 - event.$spots.length * 2}></Grid>
                        {event.$spots &&
                            Array.from(event.$spots)
                                .sort((a, b) => {
                                    return a.$length - b.$length
                                })
                                .map((spot, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={2}
                                            key={'spot-column-' + index}
                                            className="ck-table-head text-center"
                                        >
                                            {spot.$type === 'MC' ? (
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        textAlign: 'center',
                                                        fontSize: '0.9rem',
                                                    }}
                                                >
                                                    MC
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        textAlign: 'center',
                                                        fontSize: '0.9rem',
                                                    }}
                                                >
                                                    {spot.$length} min.
                                                </div>
                                            )}
                                        </Grid>
                                    )
                                })}
                    </Grid>
                    <CKGrowableScrollbar>
                        <Grid container spacing={2}>
                            {users &&
                                users.map((user, index) => {
                                    return renderUserRow(user)
                                })}
                        </Grid>
                    </CKGrowableScrollbar>

                    {/* <div style={{ height: '100%', minHeight: '50vh' }}>

					</div> */}
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <MKButton
                            variant="gradient"
                            color="info"
                            className="w-100"
                            onClick={async (e) => {
                                await onInvite(selection)
                            }}
                        >
                            <ContactMailIcon className="me-2" />
                            Uitnodigen
                        </MKButton>
                    </Grid>
                    <Grid item xs={6}>
                        <MKButton
                            variant="outlined"
                            color="info"
                            className="w-100"
                            onClick={(e) => {
                                onClose()
                            }}
                        >
                            <ClearIcon className="me-2" />
                            Annuleren
                        </MKButton>
                    </Grid>
                </Grid>
            </CKModalBody>
        </CKModal>
    )
}
export default CKLineupInviteModal

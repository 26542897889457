import { DEFAULT_PROFILE_PICTURE } from 'constants/images'
import ImageService from 'services/ImageService'
import { v4 as uuid } from 'uuid'
import Image from './Image'
import PendingImage from './PendingImage'
import { Picture } from './interfaces/Images'
import { EditableImage } from './types/ImageTypes'

interface CustomLineupSpotProps {
    id?: string
    first_name?: string
    last_name?: string
    isMc?: boolean
    picture?: Image
}

export default class CustomLineupSpot implements Picture {
    private id: string
    private first_name: string | undefined
    private last_name: string | undefined
    private isMc: boolean
    private picture: EditableImage

    private picture_id: string | undefined

    constructor({
        id = uuid(),
        first_name,
        last_name,
        isMc = false,
        picture,
    }: CustomLineupSpotProps = {}) {
        this.id = id
        this.first_name = first_name
        this.last_name = last_name
        this.isMc = isMc
        this.picture = picture
    }

    public static fromJSON(json: any) {
        const result = new CustomLineupSpot({
            id: json.id,
            first_name: json.first_name,
            last_name: json.last_name,
            isMc: typeof json.isMc === 'boolean' ? json.isMc : false,
            picture: json.picture ? Image.fromJSON(json.picture) : undefined,
        })

        return result
    }

    public get $id(): string {
        return this.id
    }
    public set $id(value: string) {
        this.id = value
    }

    public get $name(): string {
        return (this.first_name ?? '') + ' ' + (this.last_name ?? '')
    }

    public get $first_name(): string | undefined {
        return this.first_name
    }
    public set $first_name(value: string | undefined) {
        this.first_name = value
    }

    public get $last_name(): string | undefined {
        return this.last_name
    }
    public set $last_name(value: string | undefined) {
        this.last_name = value
    }

    public get $isMc(): boolean {
        return this.isMc
    }
    public set $isMc(value: boolean) {
        if (typeof value === 'boolean') {
            this.isMc = value
        } else {
            throw new Error('isMc must be a boolean')
        }
    }

    /**
     * Picture
     */
    public get $picture(): EditableImage {
        return this.picture
    }
    public set $picture(value: EditableImage) {
        this.picture = value
        if (value instanceof Image) {
            this.picture_id = value.$id
        } else {
            this.picture_id = undefined
        }
    }
    public get $pictureUrl(): string {
        if (this.picture instanceof PendingImage) {
            return this.picture.$previewUrl
        }
        if (this.picture instanceof Image) {
            return this.picture.$url
        }
        return DEFAULT_PROFILE_PICTURE
    }

    public validate(): boolean {
        const errors: any = {}

        const defaultMsg = 'Verplicht veld!'

        // Validate first_name
        errors['first_name'] = !this.$first_name ? defaultMsg : false

        // Validate last_name
        errors['last_name'] = !this.$last_name ? defaultMsg : false

        return errors
    }

    public get $className(): string {
        return 'custom_lineup_spot'
    }

    public async uploadPendingImage(): Promise<void> {
        if (this.picture instanceof PendingImage) {
            try {
                const image = await ImageService.upload({
                    file: this.picture.$file,
                    name: `${this.$name} - PICTURE`,
                    type: 'LOGO',
                })
                this.$picture = image
            } catch (e) {
                console.error(`Error uploading picture for ${this.$name}:`, e)
                throw e
            }
        }
    }

    public toJSON() {
        return {
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            isMc: this.isMc,
            picture: this.picture,
            picture_id: this.picture_id,
        }
    }
}

import Event from 'models/Event'
import Location from 'models/Location'
import CKIndexProps from 'utils/CKIndexProps'
import AuthService, { addSearchParams, useSWRPaginated } from '../AuthService'
import { LOCATIONS, ME, USERS } from '../Constants'
import useSWR from 'swr'

export function useLocation(slug: string) {
    const { data, ...props } = useSWR(`${LOCATIONS}/${slug}`)
    if (!data) return { location: null, ...props }

    const location = Location.fromJSON(data)
    return {
        location,
        ...props,
    }
}
import { useMemo, useState } from 'react'

export function useUserLocations(search: string = '', page: number = 1, perPage: number = 10) {
    const { data, ...props } = useSWRPaginated(`${USERS}/${ME}/event-locations?page=${page}&per_page=${perPage}&search=${search}`)

    const _locations = []
    if (data)
        for (const l of data) {
            _locations.push(Location.fromJSON(l))
        }

    return {
        locations: _locations,
        hasLocations: data && data.length > 0,
        ...props,
    }
}

export function useLocations(getProps: CKIndexProps) {
    const path = addSearchParams(LOCATIONS, getProps)
    const { data, ...props } = useSWRPaginated(path)

    const _locations = []
    if (data)
        for (const l of data) {
            _locations.push(Location.fromJSON(l))
        }

    return {
        locations: _locations,
        hasLocations: data && data.length > 0,
        ...props,
    }
}

export function useLocationEdit(slug: string) {
    const [location, setLocation] = useState<Location | null>(null)
    const [loading, setLoading] = useState(true)

    useMemo(() => {
        if (slug) {
            if (slug === 'new') {
                setLocation(new Location())
                setLoading(false)
            } else {
                LocationService.get(slug).then((e) => {
                    setLocation(e)
                    setLoading(false)
                });
            }
        }
    }, [slug])


    const memoizedValue = useMemo(() => {
        return { location, loading };

    }, [location, loading])

    return memoizedValue
}

const LocationService = {
    get: async function (slug: string) {
        const resp = await AuthService.get(`${LOCATIONS}/${slug}`)

        const loc = Location.fromJSON(resp.data)
        return loc
    },
    update: async function (location: Location) {
        const resp = await AuthService.put(`${LOCATIONS}/${location.$slug}`, location)

        const loc = Location.fromJSON(resp.data)
        return loc
    },
    post: async function (location: Location) {
        const resp = await AuthService.post(`${LOCATIONS}`, location)

        const loc = Location.fromJSON(resp.data)
        return loc
    },
    index: async function (data: CKIndexProps) {
        const resp = await AuthService.get(`${LOCATIONS}`, data)

        const locs = []

        for (const json of resp.data.data) {
            locs.push(Location.fromJSON(json))
        }
        resp.data.data = locs
        return resp.data
    },
    userLocations: async function (search: string = '', page: number = 1, perPage: number = 10) {
        const resp = await AuthService.get(
            `${USERS}/${ME}/event-locations?page=${page}&per_page=${perPage}&search=${search}`
        )

        const locs = []

        for (const json of resp.data.data) {
            locs.push(Location.fromJSON(json))
        }
        resp.data.data = locs

        return resp.data.data
    },
    upcomingEvents: async function (slug: string, page: number = 1, perPage: number = 10) {
        const resp = await AuthService.get(`${LOCATIONS}/${slug}/upcoming-events`, {
            page: page,
            per_page: perPage,
        })

        const events = []
        for (const json of resp.data.data) {
            events.push(Event.fromJSON(json))
        }
        resp.data.events = events
        return resp.data
    },
}
export default LocationService

import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { DeleteIcon } from 'components/UI/CKIcons'
import { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import './style.scss'

interface Props {
    onFileSelect: (file: File | null) => void
    fieldHasImage: boolean
    displayUrl: string
}

const CKImageUpload: FC<Props> = ({ onFileSelect, fieldHasImage, displayUrl }) => {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                onFileSelect(acceptedFiles[0])
            }
        },
        [onFileSelect]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] },
        maxFiles: 1,
    })

    const renderImage = () => (
        <div
            className="overlay-container p-3 h-100 d-flex justify-content-center rounded align-items-center relative"
            style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
        >
            <img src={displayUrl} width={'100%'} className="rounded" alt="" />
            <div className="overlay" onClick={() => onFileSelect(null)}>
                <DeleteIcon fontSize="large" />
            </div>
        </div>
    )

    const renderUploadComponent = () => {
        return (
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                    className="d-flex justify-content-center align-items-center p-2 border-secondary rounded border-2"
                    style={{ border: '2px dashed', height: '150px' }}
                >
                    {isDragActive ? (
                        <p>Drop uwe foto hier ...</p>
                    ) : (
                        <div className="text-center">
                            <MKButton variant="gradient" color="info" className="mb-4">
                                Selecteer een foto
                            </MKButton>
                            <MKTypography variant="body2">Of drop 'm gewoon hier</MKTypography>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return <>{fieldHasImage ? renderImage() : renderUploadComponent()}</>
}

export default CKImageUpload

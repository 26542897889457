/**
 * This is used to store an image before it is uploaded to the server
 * and to be able to display a preview of the image. This way no unnecessary
 * image is uploaded to the server.
 */
interface PendingImageProps {   
    file: File
    previewUrl: string
}

export default class PendingImage {
    private file: File
    private previewUrl: string

    constructor({ file, previewUrl }: PendingImageProps) {
        this.file = file
        this.previewUrl = previewUrl
    }

    public get $file(): File {
        return this.file
    }
    public set $file(value: File) {
        this.file = value
    }

    public get $previewUrl(): string {
        return this.previewUrl
    }
    public set $previewUrl(value: string) {
        this.previewUrl = value
    }
}

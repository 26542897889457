import { Grid } from '@mui/material'
import MKInput from 'components/MaterialKit/MKInput'
import CKDatePicker from 'components/Form/CKDatePicker'
import User from 'models/User'
import { FC } from 'react'

interface Props {
    user: User
}

const AboutForm: FC<Props> = ({ user }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CKDatePicker
                    label="Begonnen met comedy"
                    onChange={(date) => (user.$started_at = date)}
                    defaultValue={user.$started_at}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={12}>
                <MKInput
                    type="text"
                    label="Beschrijving"
                    defaultValue={user.$description}
                    onChange={(e) => (user.$description = e.target.value)}
                    fullWidth
                    rows={4}
                    multiline
                />
            </Grid>
        </Grid>
    )
}

export default AboutForm 
import { Grid } from '@mui/material'
import MKInput from 'components/MaterialKit/MKInput'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import User from 'models/User'
import { FC } from 'react'

interface Props {
    user: User
    errors: Record<string, any>
}

const GeneralForm: FC<Props> = ({ user, errors }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <MKInput
                    label="Voornaam"
                    error={errors['first_name'] ? true : false}
                    defaultValue={user.$first_name}
                    onChange={(e) => {
                        user.$first_name = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['first_name']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12} md={6}>
                <MKInput
                    label="Achternaam"
                    error={errors['last_name'] ? true : false}
                    defaultValue={user.$last_name}
                    onChange={(e) => {
                        user.$last_name = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['last_name']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12}>
                <MKInput
                    type="email"
                    label="Email"
                    error={errors['email'] ? true : false}
                    defaultValue={user.$email}
                    onChange={(e) => {
                        user.$email = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['email']}</CKFormFeedback>
            </Grid>
        </Grid>
    )
}

export default GeneralForm 
import Organisation from 'models/Organisation'
import { ORGANISATIONS } from 'services/Constants'
import useSWR from 'swr'

export function useOrganisation(slug: string) {
    const { data, ...props } = useSWR(`${ORGANISATIONS}/${slug}`)
    if (!data) return { organisation: null, ...props }

    const organisation = Organisation.fromJSON(data)
    return {
        organisation,
        ...props,
    }
}